import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './logo1.png';
import './App.css';

function App() {
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    axios.get('/api/vulnerabilities')
      .then(response => {
        setVulnerabilities(response.data);
      })
      .catch(error => {
        console.error('There was an error making the request:', error.response ? error.response.data : error.message);
      });
  }, []);

  const handleSearch = () => {
    setFilteredResults(
      vulnerabilities.filter(vuln =>
        vuln.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        vuln["CVE#"].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Leviathan Vulnerability Database</h1>
        <input
          type="text"
          placeholder="Search vulnerabilities..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </header>
      <div className="search-results">
        <ul>
          {filteredResults.map((vuln, index) => (
            <li key={index}>
              <div className="cve-id">{vuln["CVE#"]}</div>
              <div className="cve-name">{vuln.name}</div>
              <div className="cve-date">{vuln.published_date}</div>
              <div className="cve-score">{vuln.cvss3_score}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
